import React, { useEffect, useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";

import Demo from "@/assets/images/Demo.png";
import { Button } from "@/components/ui/button";

import Nav from "./components/Nav";
import NavRight from "./components/NavRight";
import { getTokenFromStorage } from "../auth/api/auth";

const HomePage = () => {
  const navigate = useNavigate();
  const [urlStudio, setUrlStudio] = useState("");

  useEffect(() => {
    const authInfo = getTokenFromStorage();
    if (!authInfo) return;
    const params = {
      ...JSON.parse(authInfo),
      callback: "/studio",
    };
    setUrlStudio(`http://localhost:5173/token/${btoa(JSON.stringify(params))}`);
  }, []);
  return (
    <div className="pt-[100px]">
      <Nav />
      <div className="pl-[280px] pr-[350px]">
        <section className="bg-white dark:bg-gray-900">
          <div className="grid max-w-screen-xl px-4 pt-10 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-20">
            <div className="mr-auto place-self-center lg:col-span-7">
              <h1 className="max-w-2xl mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-5xl dark:text-white">
                Nền tảng làm việc trọn gói của bạn
              </h1>
              <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                Quản lý thông tin, quy trình làm việc và con người, tất cả ở
                cùng một nơi.
              </p>
              <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                <NavLink
                  to={urlStudio}
                  target="_blank"
                  className=" inline-flex items-center justify-center whitespace-nowrap rounded text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none bg-secondary text-secondary-foreground hover:bg-secondary-700 hover:shadow-sm focus:shadow-active disabled:bg-secondary-300 font-medium px-[40px]"
                >
                  Taga Studiø
                </NavLink>
                <Button
                  variant="outline"
                  className="px-[40px]"
                >
                  Liên hệ
                </Button>
              </div>
            </div>
            <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
              <img
                src={Demo}
                alt="Logo taga"
                className="mb-4 mx-auto"
              />
            </div>
          </div>
        </section>
        <section className="bg-secondary-50">
          <div className="p-10">
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                Làm việc với các công cụ bạn đã sử dụng
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Cung cấp trải nghiệm dịch vụ tuyệt vời nhanh chóng - không phức
                tạp như các giải pháp ITSM truyền thống. Tăng tốc công việc phát
                triển quan trọng, loại bỏ công sức và triển khai các thay đổi
                một cách dễ dàng.
              </p>

              <ul className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-primary dark:text-purple-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Tích hợp và triển khai liên tục
                  </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-primary dark:text-purple-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Quy trình phát triển
                  </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-primary dark:text-purple-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Quản lý công việc
                  </span>
                </li>
              </ul>
              <p className="mb-8 font-light lg:text-xl">
                Cung cấp trải nghiệm dịch vụ tuyệt vời một cách nhanh chóng - mà
                không cần đến các giải pháp ITSM truyền thống phức tạp.
              </p>
            </div>
          </div>
        </section>
      </div>
      <NavRight />
    </div>
  );
};

export default HomePage;
