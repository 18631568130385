import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";

import { INotification } from "../types/notification.type";

export const NOTIFICATION_TEXT: Record<
  string,
  { text: string; path: string[] }
> = {
  NT_1: {
    text: "<b><b>${0}</b></b> đã mời bạn tham gia ${groupType} <b>${1}</b>.",
    path: ["sender.name||sender.email", "groupInfo.groupName"],
  },
  NT_2: {
    text: "Bạn đã trở thành thành viên của nhóm <b><b>${0}</b></b>.",
    path: ["groupInfo.groupName"],
  },
  NT_3: {
    text: "Yêu cầu tham gia nhóm <b>${0}</b> của bạn đã bị từ chối.",
    path: ["groupInfo.groupName"],
  },
  NT_4: {
    text: "<b>${0}</b> của bạn đã được xác thực vào tổ chức <b>${1}</b>.",
    path: ["deviceInfo.deviceUniqueId", "groupInfo.groupName"],
  },
  NT_5: {
    text: "Yêu cầu xác thực thiết bị <b>${0}</b> trong tổ chức <b>${1}</b> đã bị từ chối.",
    path: ["deviceInfo.deviceUniqueId", "groupInfo.groupName"],
  },
  NT_6: {
    text: "Thiết bị <b>${0}</b> của bạn đã bị gỡ khỏi tổ chức <b>${1}</b>.",
    path: ["deviceInfo.deviceUniqueId", "groupInfo.groupName"],
  },
  NT_7: {
    text: "Yêu cầu rời khỏi ${groupType} <b>${0}</b> của bạn đã được đồng ý.",
    path: ["groupInfo.groupName"],
  },
  NT_8: {
    text: "Yêu cầu rời khỏi ${groupType} <b>${0}</b> của bạn đã bị từ chối.",
    path: ["groupInfo.groupName"],
  },
  NT_9: {
    text: "Lời mời tham gia nhóm <b>${0}</b> của bạn đã được đồng ý bởi <b>${1}</b>.",
    path: ["groupInfo.groupName", "sender.name||sender.email"],
  },
  NT_10: {
    text: "Lời mời tham gia nhóm <b>${0}</b> của bạn đã bị từ chối bởi <b>${1}</b>.",
    path: ["groupInfo.groupName", "sender.name||sender.email"],
  },
  NT_11: {
    text: "<b>${0}</b> đã gửi yêu cầu tham gia <b>${1}</b>.",
    path: ["sender.name||sender.email", "groupInfo.groupName"],
  },
  NT_12: {
    text: "<b>${0}</b> đã tham chiếu thư mục <b>${1}</b> về nhóm <b>${2}</b>.",
    path: [
      "sender.name||sender.email",
      "contentInfo.name",
      "groupInfo.groupName",
    ],
  },
  NT_13: {
    text: "<b>${0}</b> đã gửi yêu cầu xác thực thiết bị tới tổ chức <b>${1}</b>",
    path: ["sender.name||sender.email", "groupInfo.groupName"],
  },
  NT_14: {
    text: "Nhóm <b>${0}</b> đã tham chiếu thư mục <b>${1}</b> của nhóm bạn.",
    path: ["groupInfo.groupName", "contentInfo.name"],
  },
  NT_15: {
    text: "Nhóm <b>${0}</b> đã tham chiếu tệp <b>${1}</b> của nhóm bạn.",
    path: ["groupInfo.groupName", "contentInfo.name"],
  },
  NT_16: {
    text: "<b>${0}</b> vừa gửi yêu cầu truy cập thư mục <b>${1}</b>.",
    path: ["sender.name||sender.email", "contentInfo.name"],
  },
  NT_17: {
    text: "Yêu cầu truy cập thư mục <b>${0}</b> của bạn đã được đồng ý.",
    path: ["contentInfo.name"],
  },
  NT_18: {
    text: "Yêu cầu truy cập thư mục <b>${0}</b> của bạn đã bị từ chối.",
    path: ["contentInfo.name"],
  },
  NT_19: {
    text: "<b>${0}</b> vừa gửi yêu cầu truy cập tệp tin <b>${1}</b>.",
    path: ["sender.name||sender.email", "contentInfo.name"],
  },
  NT_20: {
    text: "Yêu cầu truy cập tệp tin <b>${0}</b> của bạn đã được đồng ý.",
    path: ["contentInfo.name"],
  },
  NT_21: {
    text: "Yêu cầu truy cập tệp tin <b>${0}</b> của bạn đã bị từ chối.",
    path: ["contentInfo.name"],
  },
  NT_22: {
    text: "Nhóm <b>${0}</b> đã đồng ý tệp tin tải lên của bạn.",
    path: ["groupInfo.groupName"],
  },
  NT_23: {
    text: "Nhóm <b>${0}</b> đã từ chối tệp tin tải lên của bạn.",
    path: ["groupInfo.groupName"],
  },
  NT_24: {
    text: "Bạn đã được cấp quyền truy cập vào thư mục <b>${0}</b> của nhóm <b>${1}</b>.",
    path: ["contentInfo.name", "groupInfo.groupName"],
  },
  NT_25: {
    text: "Bạn đã được cấp quyền truy cập vào tệp tin <b>${0}</b> của nhóm <b>${1}</b>.",
    path: ["contentInfo.name", "groupInfo.groupName"],
  },
  NT_26: {
    text: "Bạn đã bị gỡ vai trò <b>${0}</b> trong ${groupType} <b>${1}</b>.",
    path: ["roleInfo.code", "groupInfo.groupName"],
  },
  NT_27: {
    text: "Bạn đã được gắn vai trò <b>${0}</b> trong ${groupType} <b>${1}</b>.",
    path: ["roleInfo.code", "groupInfo.groupName"],
  },
  NT_28: {
    text: "Bạn đã được thêm vào Tác vụ <b>${0}</b>.",
    path: ["groupInfo.groupName"],
  }, // pending
  NT_29: {
    text: "Bạn được nhắc đến trong một Node của Mindmap.",
    path: ["groupInfo.groupName"],
  }, // pending
  NT_30: {
    text: "Bạn được nhắc đến trong một bình luận trong Node.",
    path: [],
  },
  NT_31: {
    text: "Owner/Admin <b>${0}</b> của nhóm gửi yêu cầu thêm người dùng <b>${1}</b> vào nhóm con",
    path: ["recipient.name", "sender.name||sender.email"],
  },
  NT_32: {
    text: "<b>${0}</b> yêu cầu rời khỏi ${groupType} <b>${1}</b>",
    path: ["sender.name||sender.email", "groupInfo.groupName"],
  },
  NT_33: {
    text: "Bạn đã được gán chức danh <b>${0}</b> trong ${groupType} <b>${1}</b>.",
    path: ["roleInfo.code", "groupInfo.groupName"],
  },
  NT_34: {
    text: "Bạn đã bị gỡ chức danh <b>${0}</b> trong ${groupType} <b>${1}</b>.",
    path: ["roleInfo.code", "groupInfo.groupName"],
  },
  NT_35: {
    text: "Người dùng <b>${0}</b> đã chia sẻ <b>${1}</b> với bạn.",
    path: ["sender.name||sender.email", "contentInfo.name"],
  },
  NT_36: {
    text: "Người dùng <b>${0}</b> đã chia sẻ <b>${1}</b> với bạn. ",
    path: ["sender.name||sender.email", "contentInfo.name"],
  },
  NT_37: {
    text: "Người dùng <b>${0}</b> đã chuyển quyền chủ sở hữu của Tổ chức <b>${1}</b> cho bạn",
    path: ["sender.name||sender.email", "groupInfo.groupName"],
  },
  NT_38: {
    text: "Người dùng <b>${0}</b> đã chuyển quyền chủ sở hữu của Nhóm <b>${1}</b> cho bạn",
    path: ["sender.name||sender.email", "groupInfo.groupName"],
  },
};

export const getNotificationText = (item: INotification): string => {
  const result = cloneDeep(
    NOTIFICATION_TEXT[item.notificationType] || NOTIFICATION_TEXT.MSG12
  );

  if (
    Array.isArray(result.path) &&
    result.path.length > 0 &&
    typeof result.text === "string"
  ) {
    result.path.forEach((p, index) => {
      const fallbackPaths = p.split("||");
      const value = fallbackPaths
        .map((path) => get(item, path.trim(), null))
        .find((v) => v !== null && v !== undefined && v !== "");

      // Replace placeholder with the value or a default
      result.text = result.text.replaceAll(`\${${index}}`, value || "  ");
    });
  }
  result.text = result.text.replace(
    "${groupType}",
    item.groupInfo?.isOrganization ? "tổ chức" : "nhóm"
  );
  return result.text;
};
