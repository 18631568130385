export enum GroupEndpointsEnum {
  GROUP = "/group/",
  MY_GROUP = "/group/me",
  DISCOVER_GROUP = "/group/recommend",
  SEARCH_GROUP = "/group/search",
  SETTING_GROUP = "/group/setting",
  DETAIL = "/group/info?id=:id",
  CONFIRM_DELETE = "/group/confirm-delete/?groupId=:id",
  DELETE = "/group/delete",
  DESTROY = "/group/:id/destroy",
  RESTORE = "/group/recover",
  DELETED_ROOT_GROUPS = "/group/deleted",
  DELETED_GROUPS = "/group/:id/deleted",
  GROUP_CREATE = "/group/create",
  GROUP_PHOTO = "/photo",
  GROUP_DELETE_PHOTO = "/photo?groupId=:id",
  UPLOAD_PHOTO = "/group/:id/image",
  LOCK_MODE = "/group/:id/lock",
  USER_REQUEST_REQUIRED_MODE = "/group/:id/approval/user/switch",
  DEVICE_CANCEL_REQUEST = "/group/:id/device/request",
  DEVICE_REQUEST_MODE = "/group/:id/approval/switch",
  DEVICE_REQUEST = "/group/device/request/",
  DEVICE_APPROVED = "/device/group/",
  REMOVE_DEVICE = "/group/device",
  DEVICE_PERSONAL_REQUEST = "/group/device/request/personal/",
  INVITE_FRIEND = "/group/invite-friend/",
  INVITE_MEMBER = "/group/invite-member/",
  GROUP_INVITE = "/group/invite",
  GROUP_INVITE_CANCEL = "/group/invite/cancel",
  PERSONAL_INVITE = "/group/invite/me",
  PERSONAL_INVITE_ROOT = "/group/invite/me-root",
  PERSONAL_INVITE_RESPOND = "/group/invite?accept=:status&groupId=:groupId",
  PERSONAL_EMAIL_INVITE_RESPOND = "/group/invite/email?accept=:status&groupId=:groupId",
  PERSONAL = "/group/personal/",
  USER = "/group/user",
  MEMBER_ROLE = "/group/member/role",
  USER_REQUEST = "/group/request/me",
  USER_REQUEST_ROOT = "/group/request/me-root",
  GROUP_REQUEST_UPDATE = "/group/request",
  GROUP_REQUEST = "/group/requests",
  USER_CANCEL_REQUEST_TO_GROUP = "/group/request/cancel",
  USER_REQUEST_TO_GROUP = "/group/request/join",
  UPDATE_REQUEST_TO_GROUP = "/group/request",
  USER_REQUEST_PERSONAL = "/group/user/request/personal",
  TREE_MAP = "/group/structures",
  RELATIONSHIP_FRIEND = "/relationship/friend-new",
  INVITE = "/group/invite",
  DEVICE = "/group/device/request",
  DEVICE_VERIFY = "/group/device/verify",
  LEAVE_REQUEST = "/group/leave/request",
  TRANSFER_MEMBER = "/group/members/transfer-group",
  TRANSFER_REQUEST = "group/transfer-ownership/request",
  TRANSFER_ACCEPT = "group/transfer-ownership/accept",
  MEMBERS_GROUP = "/group/members",
  MEMBER_GROUP = "/group/member",
  GROUP_DEVICE = "/group/device",
  GROUP_DEVICE_REMOVE = "/group/device/remove",
  MY_GROUP_DEVICE = "/group/device/user",
  REMOVE_MY_DEVICE = "/group/device/me/remove",
  VERIFY_REMOVE_MY_DEVICE = "/group/device/me/remove/verify",
  UPDATE_GROUP_DEVICE_STATUS = "/group/device/accept",
  CANCEL_DEVICE_AUTHENTICATION_REQUEST = "/group/device/cancel",
  GROUP_STRUCTURE = "/group/structure-detail",
  GROUP_STRUCTURES = "/group/structures",
  DELETE_SUB_GROUP = "/group/delete-subgroup",
  RECOVER_GROUP = "/group/recover-subgroup",
  GROUP_STRUCTURES_DETAIL = "/group/structure-detail",
  SUB_GROUP = "/group/sub-group/info",
  TOGGLE_NOTIFY = "/group/toggle-notification",
}
