import React, { useEffect, useState } from "react";

import {
  Checkmark,
  CheckmarkFilled,
  NotificationFilled,
  NotificationOff,
  OverflowMenuHorizontal,
  Settings,
  TrashCan,
} from "@carbon/icons-react";
import clsx from "clsx";
import { Loader2 } from "lucide-react";
import InfiniteScroll from "react-infinite-scroll-component";

import { NotificationDot } from "@/assets/icons";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useToast } from "@/components/ui/use-toast";
import { PAGE_SIZE_DEFAULT } from "@/constants/app.constants";
import {
  acceptGroupInvitation,
  updateGroupDeviceStatus,
  updateStatusJoinRequest,
} from "@/features/groups/api/group.api";
import { rejectAccessRequest } from "@/features/lib/api/lib.api";
import usePagination from "@/hooks/usePagination";
import useToastError from "@/hooks/useToastError";
import { cn } from "@/lib/utils";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatDistance } from "@/utils/date";
import { getMessage } from "@/utils/message";

import NotificationComp from "./component/Notification";
import {
  deleteNotification,
  getNotification,
  markAllAsRead,
  markNotification,
} from "../../api/notification.api";
import {
  NOTIFICATION_ACTION_TYPES,
  NotificationStatus,
} from "../../constants/notification.constant";
import { notificationAction } from "../../redux/notification.slice";
import { INotification } from "../../types/notification.type";
import { getNotificationText } from "../../utils/notificationText";

const Notification = () => {
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector((state) => state.notification);
  const [tab, setTab] = useState("all");
  const { showToastError } = useToastError();
  const { pagination, setPagination } = usePagination();
  const [isLoadMore, setIsLoadMore] = useState(false);

  const fetchNotification = async (page: number, type: string) => {
    try {
      const result = await getNotification({
        pageNumber: page,
        pageSize: PAGE_SIZE_DEFAULT,
        notificationStatusFilter:
          type === "unread" ? NotificationStatus.UNREAD : undefined,
      });
      setPagination({
        current: page,
        size: pagination.size,
        total: result.totalPage,
      });
      setIsLoadMore(page < result.totalPage);
      if (page === 1) {
        dispatch(notificationAction.setNotification(result.data));
      } else {
        dispatch(
          notificationAction.setNotification([...notifications, ...result.data])
        );
      }
    } catch (error) {
      showToastError(error);
    }
  };

  const handleDeleteNotification = async (id: number) => {
    try {
      await deleteNotification(id);
      toast({
        title: "Thành công",
        variant: "success",
        description: getMessage("MSG_TB23"),
      });
      fetchNotification(1, tab);
    } catch (error) {
      showToastError(error);
    }
  };

  const handleMarkNotification = async (
    id: number,
    status: NotificationStatus
  ) => {
    try {
      await markNotification(id, status);
      fetchNotification(1, tab);
    } catch (error) {
      showToastError(error);
    }
  };

  const handleMarkAllNotificationAsRead = async () => {
    try {
      await markAllAsRead();
      fetchNotification(1, tab);
    } catch (error) {
      showToastError(error);
    }
  };
  useEffect(() => {
    fetchNotification(1, tab);
  }, [tab]);
  const handleClickAction = async (item: INotification, accept: boolean) => {
    try {
      switch (item.notificationType) {
        case "NT_1":
          await acceptGroupInvitation(accept, Number(item.inviteInfo.inviteId));
          toast({
            title: "Thành công",
            variant: "success",
            description:
              (accept ? "Chấp nhận" : "Từ chối") +
              " yêu cầu tham gia nhóm thành công",
          });
          break;
        case "NT_11":
          await updateStatusJoinRequest({
            id: Number(item.inviteInfo.inviteId),
            accept: accept,
            groupId: item.groupInfo.groupId,
          });
          toast({
            title: "Thành công",
            variant: "success",
            description:
              (accept ? "Chấp nhận" : "Từ chối") +
              " yêu cầu tham gia nhóm thành công",
          });
          break;
        case "NT_13":
          await updateGroupDeviceStatus({
            groupId: item.groupInfo.groupId,
            userId: item.sender.id,
            deviceUniqueId: item.deviceInfo.deviceUniqueId,
            accept,
          });
          toast({
            title: "Thành công!",
            variant: "success",
            description: (
              <p>
                Đã {accept ? "phê duyệt" : "từ chối"} thiết bị{" "}
                <span className="font-semibold">
                  {item.deviceInfo.deviceUniqueId}
                </span>
              </p>
            ),
          });
          break;
        case "NT_19":
          await rejectAccessRequest(
            item.contentInfo.contentAttributeId,
            accept
          );
          toast({
            title: "Thành công!",
            description: getMessage(
              accept ? "MSG74" : "MSG75",
              item.sender.name || "Taga User"
            ),
          });
          break;
        default:
          break;
      }
      fetchNotification(1, tab);
    } catch (err) {
      showToastError(err);
    }
  };

  return (
    <div className="pt-[100px] pb-10 h-screen bg-secondary-100 min-h-screen">
      <div className="max-w-[900px] h-full bg-white rounded-xl shadow-xl mx-auto p-10 overflow-hidden">
        <NotificationComp />
      </div>
    </div>
  );
};

export default Notification;
