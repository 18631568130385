import React, { memo, useState } from "react";

import { Bullhorn, Logout, QrCode, Settings } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";

import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { AppPathsEnum } from "@/constants/app.constants";
import { removeTokenFromStorage } from "@/features/auth/api/auth";
import { authAction } from "@/features/auth/redux/auth.slice";
import { useAppDispatch, useAppSelector } from "@/redux/store";

import SettingSheet from "./SettingSheet";
import LogoutDialog from "../component/LogoutDialog";
import MyQR from "../component/MyQR";
import ProfileSheet from "../component/ProfileSheet";

const MenuSettingAccountPopup = () => {
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleLogout = async () => {
    setLoading(true);
    await new Promise(() =>
      setTimeout(() => {
        dispatch(authAction.setUserData(null));
        removeTokenFromStorage();
        navigate(AppPathsEnum.LOGIN);
      }, 1000)
    );
    setLoading(false);
    setOpenLogoutDialog(false);
  };
  return (
    <div className="text-sm px-3 bg-white shadow-[0px_10px_24px_-3px_rgba(17,24,39,0.05),_0px_4px_6px_-4px_rgba(17,24,39,0.05)] rounded-[8px] text-secondary-600 border border-solid border-secondary-200 w-[280px]">
      <div className="flex justify-center mt-4 mb-3">
        <TGNAvatar
          size="64"
          src={user?.photo}
          name={user?.name||user?.email}
        />
      </div>
      <p className="font-semibold mb-1 text-center">{user?.name}</p>
      <ProfileSheet />
      <MyQR />
      <div className="py-3 flex gap-2 items-center cursor-pointer">
        <Bullhorn />
        <span>Dung lượng & Thanh toán</span>
      </div>
      <SettingSheet />
      <div
        className="py-3 flex gap-2 items-center mb-2 cursor-pointer"
        onClick={() => setOpenLogoutDialog(true)}
      >
        <Logout className="-scale-x-100" />
        <span>Đăng xuất</span>
      </div>
      <LogoutDialog
        open={openLogoutDialog}
        onClose={() => setOpenLogoutDialog(false)}
        loading={loading}
        onOk={handleLogout}
      />
    </div>
  );
};

export default memo(MenuSettingAccountPopup);
