import React, { FC, memo } from "react";

import cx from "classnames";
import Avatar, { ReactAvatarProps } from "react-avatar";

import style from "@/components/TGNAvatar/Avatar.module.scss";

type TGNAvatarProps = {
  size?: "96" | "64" | "40" | "32" | "24" | "20" | "16";
  onClick?: () => void;
  status?: "" | "active" | "busy" | "disable";
  userPhoto?: string;
  userName?: string;
} & ReactAvatarProps;

const TGNAvatar: FC<TGNAvatarProps> = ({
  userName,
  userPhoto,
  size = "64",
  onClick,
  status,
  round = true,
  ...props
}) => {
  let iconClass = "w-[16px] h-[16px]";
  switch (size) {
    case "96":
    case "64":
      iconClass = "w-[16px] h-[16px]";
      break;
    case "40":
      iconClass = "w-[10px] h-[10px]";
      break;
    case "32":
      iconClass = "w-[8px] h-[8px]";
      break;
    case "24":
      iconClass = "w-[6px] h-[6px]";
      break;
    case "20":
      iconClass = "w-[4px] h-[4px]";
      break;
    case "16":
      iconClass = "w-[4px] h-[4px]";
      break;
    default:
      iconClass = "w-[16px] h-[16px]";
      break;
  }
  if (userPhoto) {
    return (
      <div
        className={cx(
          style["tgn-avatar"],
          style[`size-${size}`],
          "inline-block"
        )}
      >
        <img
          src={`${userPhoto}`}
          alt=""
        />
        <span
          className={cx(style["status"], style[`${status}`], iconClass)}
        ></span>
      </div>
    );
  } else {
    return (
      <div
        className={cx(
          style["tgn-avatar"],
          style[`size-${size}`],
          "inline-block"
        )}
      >
        <Avatar
          name={userName}
          size={size}
          round={round}
          onClick={onClick}
          {...props}
        />
        <span
          className={cx(style.status, style[`${status}`], iconClass)}
        ></span>
      </div>
    );
  }
};

export default memo(TGNAvatar);
