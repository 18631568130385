import React from "react";

import {
  BuildingInsights_2,
  DecisionTree,
  UserMultiple,
} from "@carbon/icons-react";
import cx from "classnames";
import { NavLink, Outlet, useParams } from "react-router-dom";

import { useAppSelector } from "@/redux/store";

import { GroupPathsEnum } from "../../constants/group.paths";

const DepartmentAndUser = () => {
  const { id = "" } = useParams();
  const groupInfo = useAppSelector((state) => state.group.data);
  const navLinks = [
    {
      url: GroupPathsEnum.GROUP_DEPARTMENT_USER,
      end: true,
      icon: <UserMultiple className="mr-2" />,
      label: "Thành viên",
      counter: groupInfo?.memberCounter,
    },
    {
      url: GroupPathsEnum.GROUP_DEPARTMENT,
      end: true,
      icon: <BuildingInsights_2 className="mr-2" />,
      label: "Nhóm",
      counter: groupInfo?.subGroupCounter,
    },
  ];
  return (
    <div className="pb-6">
      <div className="bg-white py-5 z-10 sticky top-0 border-secondary-100 border-b">
        <div className="container">
          <p className="text-lg font-semibold text-secondary-900">
            Thành viên & Cơ cấu tổ chức
          </p>
          <p className="text-xs text-secondary-600">
            Quản lý thành viên và hệ thống nhóm trong tổ chức.v.v.
          </p>
        </div>
      </div>

      <div className="bg-white z-10 sticky border-secondary-100 border-b">
        <div className="container">
          <div defaultValue="member">
            <div className="flex items-center justify-between rounded-md bg-muted text-muted-foreground py-4">
              <div>
                {navLinks.map((navLink, index) => {
                  return (
                    <NavLink
                      to={navLink.url.replace(/:id/, id)}
                      end={navLink.end}
                      key={index}
                      className={({ isActive }) =>
                        cx(
                          isActive
                            ? "text-secondary-900 bg-secondary-100"
                            : "text-secondary-900",
                          "text-secondary-900 rounded-[8px] mr-4 inline-flex items-center justify-center whitespace-nowrap px-3 py-2 text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                        )
                      }
                    >
                      {navLink.icon}
                      {navLink.label}
                      <span className="ml-2 text-secondary-600">
                        ({navLink.counter})
                      </span>
                    </NavLink>
                  );
                })}
              </div>
              <NavLink
                to={GroupPathsEnum.GROUP_TREE.replace(/:id/, id)}
                end
                className={({ isActive }) =>
                  cx(
                    isActive
                      ? "text-secondary-900 bg-secondary-100"
                      : "text-secondary-900",
                    "text-secondary-900 rounded-[8px] mr-4 inline-flex items-center justify-center whitespace-nowrap px-3 py-2 text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                  )
                }
              >
                <DecisionTree className="mr-2 rotate-90" />
                Cơ cấu tổ chức
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default DepartmentAndUser;
