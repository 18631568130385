import React, { useEffect, useState } from "react";

const NavRight = () => {
  return (
    <div className="h-[calc(100vh-70px)] fixed w-[350px]  top-[70px] right-0 overflow-hidden border-r border-secondary-100">
      <div className="w-full flex flex-col h-full overflow-y-auto">
        <div className="p-4 flex flex-col gap-3">
          <img
            className="rounded-md"
            src="https://photo2.tinhte.vn/data/attachment-files/2024/11/8533404_cover-KC135.jpg"
            alt=""
          />
          <div>
            <h4 className="text-secondary-700 font-semibold line-clamp-2">
              Lần đầu tiên máy bay Không quân Mỹ tiếp nhiên liệu trên không cho
              máy bay do Nga sản xuất
            </h4>
          </div>
        </div>
        <div className="p-4">
          <ul>
            <li></li>
          </ul>
        </div>
        <div className="p-4">
          <div className="bg-secondary-50 p-4 h-[200px] flex justify-center items-center text-gray-400">
            Ads
          </div>
        </div>
        <div className="p-4">
          <h4 className="font-semibold">Nền tảng làm việc trọn gói của bạn</h4>
          <p className="text-secondary-400 font-light">
            Quản lý thông tin, quy trình làm việc và con người, tất cả ở cùng
            một nơi.
          </p>
        </div>
      </div>
    </div>
  );
};
export default NavRight;
