export const getWebSocketURL = ({
  webkey,
  fingerprint,
  token,
}: {
  webkey: string;
  fingerprint: string;
  token: string;
}) => {
  return `wss://dev.taganow.vn/webapp-websocket?webkey=${webkey}&fingerprint=${fingerprint}`;
};
