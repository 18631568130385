/* eslint-disable */
import { HierarchyPointNode, hierarchy, tree } from "d3-hierarchy";
import { select } from "d3-selection";
import { zoom } from "d3-zoom";

import store from "@/redux/store";

import { GroupTreeMap } from "../types/group.type";
import {
  setGroupSelected,
  setShowCreateGroupChildModal,
  setZoomBehavior,
} from "../group";
import {
  DeviceGroupRequestStatusEnums,
  GroupUserRoleEnums,
} from "../constants/group.constant";
import { ICON_TREE } from "../helpers/treemap.helper";
import GroupDetailAvatar from "@/assets/images/Group-Cover.png";

export const renderTreemap = (elementId: string, treemapData: GroupTreeMap) => {
  // Set the dimensions and margins of the diagram
  const margin = { top: 20, right: 90, bottom: 30, left: 90 },
    width = 960 - margin.left - margin.right + 200,
    height = 500 - margin.top - margin.bottom;
  select("#tree").remove();
  const svg = select(elementId)
    .append("svg")
    .attr("id", "tree")
    .attr("width", "100%")
    .attr("height", "100%");
  const svgGroup = svg.append("g");
  svgGroup.attr(
    "transform",
    "translate(" + margin.left + "," + margin.top + ")"
  );
  const zoomBehavior = zoom().on("zoom", (ev) => {
    svgGroup.attr("transform", ev.transform);
  });
  store.dispatch(setZoomBehavior(zoomBehavior));
  zoomBehavior.translateTo(
    svg as any,
    -(window.innerWidth / 2) + 900,
    -(window.innerHeight / 2) + 800
  );
  svg
    .call(zoomBehavior as any)
    .on("dblclick.zoom", null)
    .on("wheel.zoom", null);
  let i = 0;

  const duration = 750;
  let root: any;

  // declares a tree layout and assigns the size
  const treemap = tree()
    .nodeSize([260, 140])
    .separation(function () {
      return 1;
    });

  // Assigns parent, children, height, depth
  root = hierarchy(treemapData, (d: any) => {
    return d.children;
  });
  root.x0 = height / 2;
  root.y0 = 0;

  update(root);

  function update(source: any) {
    // Assigns the x and y position for the nodes
    const treeData = treemap(root);

    // Compute the new tree layout.
    const nodes = treeData.descendants(),
      links = treeData.descendants().slice(1);

    // Normalize for fixed-depth.
    nodes.forEach(function (d) {
      d.y = d.depth * 220;
    });

    // ****************** Nodes section ***************************

    // Update the nodes...
    const node = svgGroup.selectAll("g.node").data(nodes, function (d: any) {
      return d.id || (d.id = ++i);
    });

    // Enter any new modes at the parent's previous position.
    const nodeEnter = node
      .enter()
      .append("g")
      .attr("id", (node) => {
        const {
          data: { group },
        } = node as HierarchyPointNode<GroupTreeMap>;
        return `g${group.groupId}`;
      })
      .attr("class", "node")
      .attr("transform", function (d) {
        return "translate(" + source.x0 + "," + source.y0 + ")";
      });

    const rectHeight = 80,
      rectWidth = 240;

    nodeEnter
      .append("foreignObject")
      .attr("width", 240)
      .attr("height", 180)
      .style("transform", "translateY(0px)")
      .html((node: any) => {
        const {
          data: { group },
        } = node as HierarchyPointNode<GroupTreeMap>;
        return `
        <div class=" flex justify-center">
          <img
          class="w-12 h-12 object-cover  rounded-md overflow-hidden"
          src=${group.photoUrl ? group.photoUrl : GroupDetailAvatar}
          alt=""
        />
        </div>
        <div class="rounded-xl -mt-5 pt-5 bg-white border border-gray-100 ${
          group.member ? "border-primary-200" : ""
        } ${group.manager ? "border-orange-200" : ""}">
        
                <div class="py-2 h-12"><p class="text-center font-medium ">${
                  group.groupName
                }</p></div>
                <div class="text-sm text-gray-400 flex justify-center items-center gap-1 p-3 pb-6">
                ${ICON_TREE.group}</i><span class="text-secondary-500">${
          group.numberOfMembers
        } thành viên</span>
                </div>
              </div>
        </div>`;
      });
    // UPDATE
    const nodeUpdate = nodeEnter.merge(node as any);

    // Transition to the proper position for the node
    nodeUpdate
      .transition()
      .duration(duration)
      .attr("transform", function (d) {
        // BEFORE ....
        //return "translate(" + d.y + "," + d.x + ")";
        // AFTER ....
        return "translate(" + d.x + "," + d.y + ")";
      });

    // Update the node attributes and style
    nodeUpdate
      .select("circle.node")
      .attr("r", 10)
      .style("fill", function (d: any) {
        return d._children ? "lightsteelblue" : "#fff";
      })
      .attr("cursor", "pointer");

    nodeUpdate
      .append("foreignObject")
      .html(
        `<div class='cursor-pointer'> ${ICON_TREE.launch}</div>
      `
      )
      .style("width", "20px")
      .style("height", "20px")
      .style("transform", "translate(215px, 35px)")
      .on("click", (_, node: any) => {
        window.open(
          `${window.location.origin}/group/${node.data.group.groupId}`
        );
      });

    nodeUpdate
      .append("foreignObject")
      .html((node: any) => {
        const {
          data: { group },
        } = node as HierarchyPointNode<GroupTreeMap>;
        return `
        <div  class="w-8 h-8 bg-secondary-200 flex items-center justify-center rounded-full hover:bg-primary-600  cursor-pointer ${
          group.manager ? "block" : "hidden"
        } ">
          ${ICON_TREE.plus}
        </div>`;
      })
      // .style("display", () => {
      //   return group.manager ? "block" : "none";
      // })
      .style("width", "40px")
      .style("height", "40px")
      .style("transform", "translate(104px, 140px)")
      .on("click", (_, node: any) => {
        store.dispatch(setGroupSelected(node.data.group));
        store.dispatch(setShowCreateGroupChildModal(true));
      });

    // Remove any exiting nodes
    const nodeExit = node
      .exit()
      .transition()
      .duration(duration)
      .attr("transform", function (d) {
        // BEFORE ....
        //return "translate(" + source.y + "," + source.x + ")";
        // AFTER ....
        return "translate(" + source.x + "," + source.y + ")";
      })
      .remove();

    // On exit reduce the node circles size to 0
    nodeExit.select("circle").attr("r", 1e-6);

    // On exit reduce the opacity of text labels
    nodeExit.select("text").style("fill-opacity", 1e-6);

    // ****************** links section ***************************

    // Update the links...
    const link = svgGroup.selectAll("path.link").data(links, function (d: any) {
      return d.id;
    });

    // Enter any new links at the parent's previous position.
    const linkEnter = link
      .enter()
      .insert("path", "g")
      .attr("class", `fill-none stroke-secondary-200 stoker-[2px]`)
      .attr("d", function (d) {
        const o = { x: source.x0, y: source.y0 };
        return diagonal(o, o);
      });

    // UPDATE
    const linkUpdate = linkEnter.merge(link as any);

    // Transition back to the parent element position
    linkUpdate
      .transition()
      .duration(duration)
      .attr("d", function (d) {
        return diagonal(d, d.parent);
      });

    // Remove any exiting links
    const linkExit = link
      .exit()
      .transition()
      .duration(duration)
      .attr("d", function (d) {
        const o = { x: source.x, y: source.y };
        return diagonal(o, o);
      })
      .remove();

    // Store the old positions for transition.
    nodes.forEach(function (d: any) {
      d.x0 = d.x;
      d.y0 = d.y;
    });

    // Creates a curved (diagonal) path from parent to the child nodes
    function diagonal(s: any, d: any) {
      // BEFORE ....
      //path = `M ${s.y} ${s.x}
      //        C ${(s.y + d.y) / 2} ${s.x},
      //          ${(s.y + d.y) / 2} ${d.x},
      //          ${d.y} ${d.x}`

      // AFTER ....
      const offset = 80;
      const path = `M ${s.x + rectWidth / 2},${s.y + offset} 
      ${s.x + rectWidth / 2},${s.y - 120 + offset} 
      ${d.x + rectWidth / 2},${s.y - 120 + offset} 
      ${d.x + rectWidth / 2},${d.y + offset}`;

      return path;
    }
  }
};
