import React, { FC, ReactNode, memo, useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";

import {
  Add,
  DataStructured,
  InformationFilled,
  Locked,
  Warning,
  Wikis,
} from "@carbon/icons-react";
import { AxiosError } from "axios";
import { Formik, Form, Field } from "formik";
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import OnBoarding from "@/assets/images/OnBoarding.png";
import OnBoarding2 from "@/assets/images/OnBroading-2.png";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import {
  setShowCreateGroupChildModal,
  setTreeMap,
} from "@/features/groups/redux/group.slice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { COMMON_MESSAGES, getMessage } from "@/utils/message";

import { createGroup, getGroupTreeMap } from "../../../api/group.api";
import { GroupModeEnums } from "../../../constants/group.constant";
import { GroupPathsEnum } from "../../../constants/group.paths";
import { GroupInfoDef } from "../../../types/group.type";

type CreateGroupModalProps = {
  rootGroup?: Partial<GroupInfoDef>;
  isFullWidth?: boolean;
  button?: ReactNode;
  rootMode?: GroupModeEnums;
  rootId: number;
  haveSubGroup?: boolean;
};

type Initial = {
  description: string;
  name: string;
  mode: GroupModeEnums;
  parentId?: number;
  inviteOwner?: boolean;
};

const CreateChildGroup: FC<CreateGroupModalProps> = ({
  rootMode = GroupModeEnums.PUBLIC,
  rootId,
  rootGroup,
}) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { user } = useAppSelector((state) => state.auth);
  const {
    data: groupData,
    showCreateGroupChildModal,
    groupSelected,
  } = useAppSelector((state) => state.group);
  const CreateGroupSchema = Yup.object().shape({
    name: Yup.string()
      .max(
        50,
        `Tên ${rootId ? "nhóm" : "tổ chức"} không được vượt quá 50 ký tự`
      )
      .trim()
      .required(`Tên ${rootId ? "nhóm" : "tổ chức"} không được bỏ trống.`),
    description: Yup.string().max(
      400,
      `Mô tả ${rootId ? "nhóm" : "tổ chức"} không vượt quá 400 ký tự`
    ),
  });
  const [existingNameError, setExistingNameError] = useState<null | string>(
    null
  );
  const [openModal, setOpenModal] = useState(showCreateGroupChildModal);
  const [hiddenModal, setHiddenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openOnboarding, setOpenOnboarding] = useState(false);
  const navigate = useNavigate();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
  };
  useEffect(() => {
    setOpenModal(showCreateGroupChildModal);
  }, [showCreateGroupChildModal]);
  console.log(showCreateGroupChildModal);
  return (
    <>
      <Dialog
        open={openModal}
        onOpenChange={(open) => {
          if (open) {
            setExistingNameError(null);
          }
        }}
      >
        <DialogContent
          className={hiddenModal ? "hidden" : "sm:max-w-[552px] bg-white gap-6"}
        >
          <div>
            <DialogTitle>{rootId ? "Tạo nhóm" : "Tạo tổ chức"}</DialogTitle>
            <DialogDescription className="text-sm text-secondary-600">
              {rootId ? (
                <p>
                  Nhóm mới được tạo sẽ trực thuộc Tổ chức, bạn có thể tuỳ chỉnh
                  vị trí nhóm trong chức năng
                  <span>
                    <DataStructured className="ml-1 mr-1 inline font-semibold text-xs" />
                    Cơ cấu tổ chức
                  </span>
                </p>
              ) : (
                "Chúng tôi sẽ tinh chỉnh trải nghiệm thiết lập của bạn cho phù hợp"
              )}
            </DialogDescription>
          </div>
          <Formik
            initialValues={{
              name: "",
              description: "",
              mode: rootMode,
            }}
            validationSchema={CreateGroupSchema}
            onSubmit={(values: Initial, { setSubmitting }) => {
              const createGroupFn = async () => {
                try {
                  if (rootId) {
                    values.parentId = rootId;
                  }
                  values.mode = Number(values.mode);
                  await createGroup(values);
                  const result = await getGroupTreeMap(rootId + "");
                  dispatch(
                    setTreeMap(
                      result.data.sort((a, b) => a.groupId - b.groupId)
                    )
                  );

                  toast({
                    title: "Thành công!",
                    variant: "success",
                    description: getMessage(
                      "MSG3",
                      `${rootId ? "nhóm" : "tổ chức"}`
                    ),
                  });
                  dispatch(setShowCreateGroupChildModal(false));
                  setOpenModal(false);
                } catch (error: any) {
                  if (error instanceof AxiosError) {
                    if (error.response?.data.errors.includes("MSG2")) {
                      setExistingNameError(
                        "Tên nhóm đã tồn tại, vui lòng điền giá trị khác."
                      );
                    }
                  } else {
                    setExistingNameError(null);
                    toast({
                      title: "Thất bại!",
                      variant: "error",
                      description: getMessage(error.response?.data.errors[0]),
                    });
                  }
                } finally {
                  setSubmitting(false);
                }
              };
              createGroupFn();
            }}
          >
            {({
              handleChange,
              setFieldValue,
              errors,
              touched,
              isSubmitting,
              isValid,
            }) => (
              <Form className="grid gap-6">
                <div className="grid gap-4">
                  <div className="items-center grid gap-2">
                    <Label className="text-sm">
                      Tên {rootId ? "nhóm" : "tổ chức"}{" "}
                      <span className="text-error-600">*</span>
                    </Label>
                    <Field
                      name="name"
                      as={Input}
                      placeholder={rootId ? "Tên nhóm" : "Tên tổ chức"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.currentTarget.value === "") {
                          setExistingNameError(null);
                        }
                        handleChange(e);
                      }}
                      error={errors.name || existingNameError}
                    />
                    {errors.name ? (
                      <p className="text-error-400 text-xs flex">
                        <Warning
                          className="text-error-400 mr-1"
                          size={16}
                        />
                        {errors.name}
                      </p>
                    ) : null}
                    {existingNameError && (
                      <p className="text-error-400 text-xs flex">
                        <Warning
                          className="text-error-400 mr-1"
                          size={16}
                        />
                        {existingNameError}
                      </p>
                    )}
                  </div>
                  <div className="items-center grid gap-2">
                    <Label className="text-sm flex items-center">
                      Quyền truy cập{" "}
                      {rootId && (
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger
                              className="ml-1"
                              asChild
                            >
                              <InformationFilled />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>
                                Quyền truy cập của Nhóm phụ thuộc vào quyền truy
                                cập của Tổ chức.
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      )}
                    </Label>
                    <Select
                      defaultValue={String(rootMode)}
                      onValueChange={(value) => setFieldValue("mode", value)}
                      disabled={rootId ? true : false}
                    >
                      <SelectTrigger className="bg-secondary-50 items-start">
                        <SelectValue defaultValue={String(rootMode)} />
                      </SelectTrigger>
                      <SelectContent className="max-w-[502px]">
                        <SelectGroup>
                          <SelectItem value="0">
                            <div className="flex align-items-start">
                              <Wikis className="w-[16px] shrink-0 mr-2.5  text-secondary-600 mt-0.5" />
                              <div className="text-left">
                                <p className="text-sm font-medium text-secondary-900">
                                  Công khai
                                </p>
                                <span className="text-xs text-secondary-600">
                                  Bất kỳ ai cũng có thể tìm thấy nhóm và gửi yêu
                                  cầu tham gia nhóm của bạn, người trong nhóm có
                                  thể gửi lời mời tới người khác
                                </span>
                              </div>
                            </div>
                          </SelectItem>
                          <SelectItem value="1">
                            <div className="flex align-items-start">
                              <Locked className="w-[16px] shrink-0 mr-2.5 text-secondary-600 mt-0.5" />
                              <div className="text-left">
                                <p className="text-sm font-medium text-secondary-900">
                                  Riêng tư
                                </p>
                                <span className="text-xs text-secondary-600">
                                  Chỉ thành viên trong nhóm hoặc người dùng được
                                  gửi lời mời tham gia mới có thể tìm thấy nhóm
                                </span>
                              </div>
                            </div>
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="items-center grid gap-2">
                    <Label className="text-sm">
                      Mô tả {rootId ? "nhóm" : "tổ chức"}
                    </Label>
                    <Field
                      name="description"
                      as={Textarea}
                      placeholder={
                        rootId
                          ? "Nhóm của chúng tôi là"
                          : "Tổ chức của chúng tôi là..."
                      }
                      onChange={handleChange}
                      error={errors.description}
                    />
                    {errors.description ? (
                      <p className="text-error-400 text-xs flex">
                        <Warning
                          className="text-error-400 mr-1"
                          size={16}
                        />
                        {errors.description}
                      </p>
                    ) : null}
                  </div>
                  {rootId && user?.id !== rootGroup?.rootOwnerId && (
                    <div className="items-top flex space-x-2">
                      <Checkbox
                        name="inviteOwner"
                        id="inviteOwner"
                        onCheckedChange={(value) => {
                          setFieldValue("inviteOwner", value);
                        }}
                      />
                      <div className="grid leading-none">
                        <label
                          htmlFor="inviteOwner"
                          className="text-sm font-medium text-secondary-900 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          Thêm chủ sở hữu
                        </label>
                        <p className="text-xs text-secondary-600 text-muted-foreground">
                          Bạn sẽ thêm chủ sở hữu của Tổ chức là thành viên của
                          Nhóm này.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <DialogFooter>
                  <Button
                    variant={"ghost"}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setHiddenModal(true);
                      setOpenConfirmModal(true);
                    }}
                    loading={isSubmitting}
                  >
                    Hủy
                  </Button>
                  <Button
                    loading={isSubmitting}
                    type="submit"
                    disabled={!isValid}
                  >
                    Tạo {rootId ? "Nhóm" : "Tổ chức"}
                  </Button>
                </DialogFooter>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <Dialog open={openConfirmModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Huỷ tạo {rootId ? "Nhóm" : "Tổ chức"}?</DialogTitle>
            <DialogDescription>
              Bạn có chắc chắn huỷ tạo {rootId ? "Nhóm" : "Tổ chức"}?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant={"ghost"}
              type="button"
              onClick={() => {
                setOpenModal(false);
                dispatch(setShowCreateGroupChildModal(false));
                setOpenConfirmModal(false);
              }}
            >
              Huỷ bỏ
            </Button>
            <Button
              onClick={() => {
                setHiddenModal(false);
                setOpenConfirmModal(false);
              }}
            >
              Tiếp tục chỉnh sửa
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(CreateChildGroup);
