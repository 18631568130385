import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ZoomBehavior } from "d3-zoom";

import {
  GetGroupInviteResponse,
  GroupInfoDef,
  GroupStructureDetailDef,
  GroupTreeMap,
  IGroupInvitation,
  IMemberGroup,
  JoinRequestedDef,
  LeaveRequestDef,
} from "@/features/groups/types/group.type";
import { PaginationResponse } from "@/types/app.types";

import {
  getGroupById,
  getGroupInvitationForUser,
  getGroupInvite,
  getGroupMembers,
  getInviteRequests,
  getLeaveRequests,
  getMemberTransferOwner,
} from "../api/group.api";
import { GroupSortEnum } from "../constants/group.constant";
import { findGroupNodeById } from "../helpers/treemap.helper";

interface GroupState {
  flagList: boolean;
  data: GroupInfoDef | null;
  treeMap: GroupTreeMap | null;
  groupList: GroupStructureDetailDef[];
  zoomBehavior: ZoomBehavior<Element, unknown> | null;
  invitationRequests: IGroupInvitation[];
  leaveRequests: LeaveRequestDef[];
  groupInviteList: GetGroupInviteResponse | null;
  joinRequestedList: JoinRequestedDef[];
  loading: boolean;
  showCreateGroupChildModal: boolean;
  groupSelected: GroupStructureDetailDef | null;
  membersList: IMemberGroup[];
  suggestTransformMembers: IMemberGroup[];
}

const initialState: GroupState = {
  flagList: false,
  data: null,
  treeMap: null,
  groupList: [],
  zoomBehavior: null,
  invitationRequests: [],
  leaveRequests: [],
  groupInviteList: null,
  joinRequestedList: [],
  suggestTransformMembers: [],
  loading: false,
  showCreateGroupChildModal: false,
  groupSelected: null,
  membersList: [],
};

export const getGroupInfo = createAsyncThunk<
  GroupInfoDef,
  { group_id: number }
>("group/getGroup", async ({ group_id }, { rejectWithValue }) => {
  try {
    const groupData = await getGroupById(Number(group_id));
    return groupData.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getLeaveRequestsThunk = createAsyncThunk<
  PaginationResponse<LeaveRequestDef[]>,
  { groupId: number; pageNumber?: number; pageSize?: number; keyword?: string }
>(
  "group/getLeaveRequests",
  async ({ groupId, pageNumber = 0, pageSize = 10 }, { rejectWithValue }) => {
    try {
      const groupData = await getLeaveRequests({
        groupId: groupId,
        pageNumber,
        pageSize,
      });
      return groupData;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getGroupInvitationForUserThunk = createAsyncThunk<
  IGroupInvitation[],
  {
    pageNumber?: number;
    pageSize?: number;
    rootGroupId?: number;
    sort?: GroupSortEnum;
  }
>(
  "group/getGroupInvitationForUser",
  async (
    { pageNumber = 1, pageSize = 10, rootGroupId, sort },
    { rejectWithValue }
  ) => {
    try {
      const groupData = await getGroupInvitationForUser({
        rootGroupId,
        sort,
        pageNumber,
        pageSize,
      });
      return groupData.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getGroupInviteThunk = createAsyncThunk<
  GetGroupInviteResponse,
  { groupId: number; keyword?: string; pageNum?: number; pageSize?: number }
>(
  "group/getGroupInvite",
  async (
    { groupId, keyword, pageNum = 0, pageSize = 10 },
    { rejectWithValue }
  ) => {
    try {
      const groupInvite = await getGroupInvite({
        groupId: groupId,
        pageNumber: pageNum,
        pageSize,
        keyword,
      });
      return groupInvite;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getJoinRequestedListThunk = createAsyncThunk<
  JoinRequestedDef[],
  { groupId: number; keyword?: string; pageNum?: number; pageSize?: number }
>("group/getJoinRequestedList", async ({ groupId }, { rejectWithValue }) => {
  try {
    const joinRequestList = await getInviteRequests({ groupId });
    return joinRequestList;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getGroupMembersListThunk = createAsyncThunk<
  IMemberGroup[],
  { groupId: number; pageNumber?: number; pageSize?: number }
>(
  "group/getGroupMembersList",
  async ({ groupId, pageNumber, pageSize }, { rejectWithValue }) => {
    try {
      const groupMembersList = await getGroupMembers({
        groupId,
        pageNumber,
        pageSize,
      });
      return groupMembersList.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSuggestTransformMembersThunk = createAsyncThunk<
  IMemberGroup[],
  {
    pageNumber?: number;
    pageSize?: number;
    groupId: number;
  }
>(
  "group/getSuggestTransformMembers",
  async ({ pageNumber = 1, pageSize = 10, groupId }, { rejectWithValue }) => {
    try {
      const groupData = await getMemberTransferOwner({
        groupId,
        pageNumber,
        pageSize,
      });
      return groupData.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    setFlagList(state, action: PayloadAction<boolean>) {
      state.flagList = action.payload;
    },
    setFlagLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setGroup(state, action: PayloadAction<GroupInfoDef | null>) {
      state.data = action.payload;
    },
    setTreeMap: (state, action: PayloadAction<GroupStructureDetailDef[]>) => {
      const groups = action.payload;
      state.groupList = groups;
      const groupRoot = groups.find((group) => !group.parentId);
      if (!groupRoot) {
        return;
      }
      const root: GroupTreeMap = {
        group: groupRoot,
        children: [],
      };
      groups.forEach((group) => {
        if (!group.parentId) return;
        const groupParent = findGroupNodeById(root, group.parentId);
        if (groupParent) {
          groupParent.children.push({
            children: [],
            group,
          });
        }
      });
      state.treeMap = root;
    },
    setShowCreateGroupChildModal: (state, action: PayloadAction<boolean>) => {
      state.showCreateGroupChildModal = action.payload;
    },
    setGroupSelected: (state, action: PayloadAction<GroupStructureDetailDef>) => {
      state.groupSelected = action.payload;
    },
    setZoomBehavior: (
      state,
      action: PayloadAction<ZoomBehavior<Element, unknown>>
    ) => {
      state.zoomBehavior = action.payload;
    },
    updateJoinRequestedList: (
      state,
      action: PayloadAction<JoinRequestedDef[]>
    ) => {
      state.joinRequestedList = action.payload;
    },
    updateLeaveRequests: (state, action: PayloadAction<LeaveRequestDef[]>) => {
      state.leaveRequests = action.payload;
    },
    updateInvitationRequests: (
      state,
      action: PayloadAction<IGroupInvitation[]>
    ) => {
      state.invitationRequests = action.payload;
    },
    updateMembersList: (state, action: PayloadAction<IMemberGroup[]>) => {
      state.membersList = action.payload;
    },
    updateSuggestTransformMembers: (
      state,
      action: PayloadAction<IMemberGroup[]>
    ) => {
      state.suggestTransformMembers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getGroupInfo.fulfilled,
      (state, action: PayloadAction<GroupInfoDef>) => {
        state.data = action.payload;
      }
    );

    builder.addCase(getGroupInvitationForUserThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getGroupInvitationForUserThunk.fulfilled,
      (state, action) => {
        state.invitationRequests = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(getGroupInvitationForUserThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getLeaveRequestsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLeaveRequestsThunk.fulfilled, (state, action) => {
      state.leaveRequests = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getLeaveRequestsThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getSuggestTransformMembersThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getSuggestTransformMembersThunk.fulfilled,
      (state, action) => {
        state.suggestTransformMembers = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(getSuggestTransformMembersThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getGroupInviteThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getGroupInviteThunk.fulfilled,
      (state, action: PayloadAction<GetGroupInviteResponse>) => {
        state.groupInviteList = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(getGroupInviteThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getJoinRequestedListThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getJoinRequestedListThunk.fulfilled,
      (state, action: PayloadAction<JoinRequestedDef[]>) => {
        state.joinRequestedList = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(getJoinRequestedListThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getGroupMembersListThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getGroupMembersListThunk.fulfilled,
      (state, action: PayloadAction<IMemberGroup[]>) => {
        state.membersList = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(getGroupMembersListThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setFlagLoading,
  setFlagList,
  setGroup,
  setTreeMap,
  setZoomBehavior,
  setShowCreateGroupChildModal,
  setGroupSelected,
  updateJoinRequestedList,
  updateLeaveRequests,
  updateInvitationRequests,
  updateSuggestTransformMembers,
} = groupSlice.actions;
export const groupActions = groupSlice.actions;
export const groupReducer = groupSlice.reducer;
