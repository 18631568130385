import React, { FC, memo } from "react";

import {
  AccessibilityColor,
  DotMark,
  OverflowMenuVertical,
  UserAvatar,
  Vlan,
} from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";

import GroupDetailAvatar from "@/assets/images/Group Detail Cover.png";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { GroupPathsEnum } from "@/features/groups/constants/group.paths";
import { GroupStructureDetailDef } from "@/features/groups/types/group.type";

type GroupItemProps = {
  groupStructure: GroupStructureDetailDef;
  showStructure: (groupStructure: GroupStructureDetailDef) => void;
};

const GroupItem: FC<GroupItemProps> = ({ groupStructure, showStructure }) => {
  const navigate = useNavigate();
  return (
    <div className="py-4 pl-3 border-secondary-200 last:border-b-0 first:border-t flex justify-between items-center hover:bg-secondary-100">
      <div
        className="flex gap-2 items-center cursor-pointer"
        onClick={() => showStructure(groupStructure)}
      >
        <TGNAvatar
          size="40"
          src={groupStructure.photoUrl || GroupDetailAvatar}
          round="12px"
        />
        <div>
          <p className="text-secondary-900 font-medium mb-0 text-sm -mb-1">
            {groupStructure.groupName}
          </p>
          <div className="flex items-center text-secondary-600 text-xs mt-1">
            {groupStructure.manager ? (
              <Badge className="bg-orange-25 border border-orange-200 text-orange-500 font-medium text-[10px] leading-[14px]">
                <UserAvatar
                  className="mr-1"
                  size={12}
                />
                Quản trị
              </Badge>
            ) : (
              <>
                {groupStructure.member ? (
                  <Badge className="bg-blue-50 border border-blue-200 text-blue-500 font-medium text-[10px] leading-[14px]">
                    <UserAvatar
                      className="mr-1"
                      size={12}
                    />
                    Đã tham gia
                  </Badge>
                ) : (
                  <Badge className="bg-gray-50 border border-gray-200 text-gray-500 font-medium text-[10px] leading-[14px]">
                    <UserAvatar
                      className="mr-1"
                      size={12}
                    />
                    Chưa tham gia
                  </Badge>
                )}
              </>
            )}
            <DotMark
              size={5}
              color="#4B5563"
              className="ml-1 mr-1"
            ></DotMark>
            {groupStructure.numberOfMembers} Thành viên
          </div>
        </div>
      </div>
      <div className="flex gap-2">
        <Button
          variant="ghost"
          onClick={() => showStructure(groupStructure)}
        >
          <Vlan className="mr-2" />
          Xem cơ cấu
        </Button>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="p-3 cursor-pointer">
              <OverflowMenuVertical />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <Button
              variant="text"
              className="w-full justify-start"
              onClick={() =>
                navigate(
                  GroupPathsEnum.GROUP_DETAIL.replace(
                    /:id/,
                    groupStructure.groupId.toString()
                  )
                )
              }
            >
              <AccessibilityColor className="mr-2" />
              Truy cập nhóm
            </Button>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default memo(GroupItem);
