import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import LogoTagaNow from "@/assets/images/taganow-logo-color.svg";
import { Button } from "@/components/ui/button";

const Header: React.FC = () => {
  const navigate = useNavigate();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header
      className={`fixed left-0 top-0 z-50 mx-auto w-full  ${
        isScrolled
          ? "bg-white border-b border-gray-200/50 dark:border-gray-700/50"
          : ""
      }`}
    >
      <div className=" mx-auto px-4 py-4 flex justify-between items-center  2xl:px-[100px]">
        <div className="flex items-center space-x-4">
          <img
            src={LogoTagaNow}
            alt="logo"
            className="h-8"
          />
        </div>
        <nav className="hidden md:flex space-x-4 gap-6">
          <a href="#*"> Sản phẩm</a>
          <a href="#*"> Quy trình mẫu</a>
          <a href="#*"> Khách hàng </a>
          <a href="#*"> Tải xuống</a>
          <a href="#*"> Giá cả</a>
          <a
            href="https://library.taganow.vn/studio"
            target="_blank"
            rel="noreferrer"
          >
            Taga Studio
          </a>
        </nav>
        <div className="flex items-center space-x-4">
          <Button
            className="hidden md:block"
            variant="text"
            onClick={() => navigate("/login")}
          >
            Đăng nhập
          </Button>
          <Button onClick={() => navigate("/login")}>Đăng ký</Button>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="md:hidden bg-white dark:bg-gray-800 shadow-md">
          <nav className="flex flex-col space-y-4 p-4">
            <a href="0"> home</a>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
