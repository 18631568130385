import React from "react";

import { Calendar, Close, OverflowMenuVertical } from "@carbon/icons-react";

import { Instagram, LinkedIn, Behance, Dribbble } from "@/assets/icons";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { authAction } from "@/features/auth/redux/auth.slice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { formatDate } from "@/utils/date";

const ProfileSheet = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  return (
    <div>
      <Sheet>
        <SheetTrigger asChild>
          <p className="text-center text-primary mb-4">
            <span className="cursor-pointer">Xem hồ sơ</span>
          </p>
        </SheetTrigger>
        <SheetContent className="h-screen overflow-y-auto">
          <SheetHeader className="relative">
            <SheetClose
              className="absolute -top-2 -right-2 cursor-pointer"
              asChild
            >
              <Close size={20} />
            </SheetClose>
            <SheetTitle className="font-semibold">Hồ sơ</SheetTitle>
            <SheetDescription>
              <div className="flex gap-6 mb-8">
                <TGNAvatar
                  size="96"
                  name={user?.name||user?.email}
                  src={user?.photo}
                />
                <div>
                  <p className="font-semibold mb-1">{user?.name}</p>
                  <p className="text-xs text-secondary-600 mb-3">
                    {user?.email}
                  </p>
                  <div className="flex gap-3">
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => dispatch(authAction.showSetting())}
                    >
                      Chỉnh sửa
                    </Button>
                    <Button
                      size="sm"
                      variant="ghost"
                    >
                      <OverflowMenuVertical />
                    </Button>
                  </div>
                </div>
              </div>
              <Tabs defaultValue="general">
                <TabsList>
                  <TabsTrigger
                    value="general"
                    className="data-[state=active]:border-secondary-900 data-[state=active]:text-secondary-900 text-secondary-900 mr-10"
                  >
                    Giới thiệu
                  </TabsTrigger>
                  <TabsTrigger
                    value="activity"
                    className="data-[state=active]:border-secondary-900 data-[state=active]:text-secondary-900 text-secondary-900"
                  >
                    Hoạt động
                  </TabsTrigger>
                </TabsList>
                <TabsContent
                  value="general"
                  className="mt-6"
                >
                  {user?.createdAt && (
                    <p className="text-sm text-secondary-600">
                      <Calendar className="mr-2 inline" />
                      Tham gia từ {formatDate(user?.createdAt)}
                    </p>
                  )}
                  {/* <div className="mt-4 flex gap-2">
                    <span className="rounded-full p-2 inline-block bg-secondary-900">
                      <LinkedIn
                        size={16}
                        color="#FFF"
                      />
                    </span>
                    <span className="rounded-full p-2 inline-block bg-secondary-900">
                      <Instagram
                        size={16}
                        color="#FFF"
                      />
                    </span>
                    <span className="rounded-full p-2 inline-block bg-secondary-900">
                      <Behance
                        size={16}
                        color="#FFF"
                      />
                    </span>
                    <span className="rounded-full p-2 inline-block bg-secondary-900">
                      <Dribbble
                        size={16}
                        color="#FFF"
                      />
                    </span>
                  </div> */}
                  {user?.biography && (
                    <div className="mt-4 py-3">
                      <p className="text-sm font-medium">Tiểu sử</p>
                      <p className="text-sm text-secondary-600">
                        {user.biography}
                      </p>
                    </div>
                  )}
                  {user?.address && (
                    <div className="mt-4 py-3">
                      <p className="text-sm font-medium">Vị trí</p>
                      <p className="text-sm text-secondary-600">
                        {user.address}
                      </p>
                    </div>
                  )}
                  {user?.location && (
                    <div className="mt-4 py-3">
                      <p className="text-sm font-medium">Vị trí</p>
                      <p className="text-sm text-secondary-600">
                        {user.location}
                      </p>
                    </div>
                  )}
                  {user?.company && (
                    <div className="mt-4 py-3">
                      <p className="text-sm font-medium">Công ty</p>
                      <p className="text-sm text-secondary-600">
                        {user.company}
                      </p>
                    </div>
                  )}
                  {/* <div className="mt-4 py-3">
                    <p className="text-sm font-medium">Sở thích</p>
                    <div>
                      <Badge
                        variant="ghost"
                        className="border-gray-200 border"
                      >
                        Design
                      </Badge>
                      <Badge
                        variant="ghost"
                        className="border-gray-200 border"
                      >
                        UX
                      </Badge>
                      <Badge
                        variant="ghost"
                        className="border-gray-200 border"
                      >
                        HCI
                      </Badge>
                      <Badge
                        variant="ghost"
                        className="border-gray-200 border"
                      >
                        Interaction Design
                      </Badge>
                    </div>
                  </div> */}
                </TabsContent>
                <TabsContent
                  value="activity"
                  className="mt-6"
                >
                  Change your password here.
                </TabsContent>
              </Tabs>
            </SheetDescription>
          </SheetHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4"></div>
          </div>
          <SheetFooter></SheetFooter>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default ProfileSheet;
