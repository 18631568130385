import React, { useCallback, useEffect, useState } from "react";

import { isAxiosError } from "axios";
import { useParams } from "react-router-dom";

import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { useToast } from "@/components/ui/use-toast";
import usePagination from "@/hooks/usePagination";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getMessage } from "@/utils/message";

import GroupItem from "./component/GroupItem";
import GroupStructureDetailSheet from "./component/GroupStructureDetailSheet";
import {
  getGroupStructureDetail,
  getGroupStructureList,
  getGroupTreeMap,
} from "../../api/group.api";
import { setTreeMap } from "../../redux/group.slice";
import { GroupStructureDetailDef, GroupTreeMap } from "../../types/group.type";

interface GroupTreeProps {
  treeMap: GroupTreeMap | null;
  handleOpenGroupStructureDetailSheet: (group: GroupStructureDetailDef) => void;
}
const DepartmentTree = () => {
  const dispatch = useAppDispatch();
  const { id = "" } = useParams();

  const groupInfo = useAppSelector((state) => state.group.data);
  const { treeMap } = useAppSelector((state) => state.group);
  const [loading, setLoading] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const { pagination, setPagination } = usePagination();
  const [totalGroup, setTotalGroup] = useState(0);
  const [openGroupStructureDetailSheet, setOpenGroupStructureDetailSheet] =
    useState(false);
  const { toast } = useToast();
  const [groupStructures, setGroupStructures] = useState<
    GroupStructureDetailDef[]
  >([]);
  const [groupStructureDetail, setGroupStructureDetail] =
    useState<GroupStructureDetailDef>();

  const fetchGroupTreemap = useCallback(async () => {
    if (!id || !groupInfo?.id) {
      return;
    }
    try {
      setLoading(true);
      const result = await getGroupTreeMap(id);
      setTotalGroup(result.totalElements);
      dispatch(setTreeMap(result.data.sort((a, b) => a.groupId - b.groupId)));
    } catch {
      toast({
        title: "Thất bại!",
        variant: "error",
        description: "Tải dữ liệu thất bại",
      });
    } finally {
      setLoading(false);
    }
  }, [id, dispatch]);
  useEffect(() => {
    fetchGroupTreemap();
    return () => {
      dispatch(setTreeMap([]));
    };
  }, []);
  const handleOpenGroupStructureDetailSheet = async (
    groupStructure: GroupStructureDetailDef
  ) => {
    try {
      const result = await getGroupStructureDetail(groupStructure.groupId);
      setGroupStructureDetail(result.data);
      setOpenGroupStructureDetailSheet(true);
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          title: "Thất bại!",
          variant: "error",
          description: getMessage(err.response?.data?.errors?.[0]),
        });
      }
    }
  };
  const GroupTree: React.FC<GroupTreeProps> = ({
    treeMap,
    handleOpenGroupStructureDetailSheet,
  }) => {
    if (!treeMap) return null;

    return (
      <div>
        {treeMap.group && (
          <GroupItem
            key={treeMap.group.groupId}
            groupStructure={treeMap.group}
            showStructure={handleOpenGroupStructureDetailSheet}
          />
        )}
        {treeMap.children.map((item) => (
          <div
            key={item.group.groupId}
            className="pl-4 border-l  border-secondary-200"
          >
            <GroupTree
              treeMap={item}
              handleOpenGroupStructureDetailSheet={
                handleOpenGroupStructureDetailSheet
              }
            />
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="min-h-[calc(100vh-280px)] bg-white">
      <div>
        <div className="container">
          <div className="flex justify-between">
            <div className="py-4 flex">
              <p className="font-semibold text-secondary-900 mr-2">
                {totalGroup} Nhóm
              </p>
            </div>
            <div
              className={"grid gap-4 auto-cols-max grid-flow-col items-center"}
            ></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="pl-2 ">
          {treeMap && (
            <GroupTree
              treeMap={treeMap}
              handleOpenGroupStructureDetailSheet={
                handleOpenGroupStructureDetailSheet
              }
            />
          )}
        </div>
      </div>
      <GroupStructureDetailSheet
        open={openGroupStructureDetailSheet}
        close={() => setOpenGroupStructureDetailSheet(false)}
        group={groupStructureDetail}
      />
    </div>
  );
};

export default DepartmentTree;
