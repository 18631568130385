import React, { useCallback, useEffect, useState } from "react";

import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import TGNLoading from "@/components/TGNLoading/TGNLoading";
import { useAppDispatch, useAppSelector } from "@/redux/store";

import CreateChildGroup from "./component/CreateChildGroup";
import { getGroupTreeMap } from "../../api/group.api";
import SearchBar from "../../components/SearchBar/SearchBar";
import { setShowCreateGroupChildModal, setTreeMap } from "../../group";
import { renderTreemap } from "../../lib/treemap";

const TreeOrg = () => {
  const dispatch = useAppDispatch();
  const {
    data: groupData,
    showCreateGroupChildModal,
    groupSelected,
  } = useAppSelector((state) => state.group);
  const { treeMap } = useAppSelector((state) => state.group);
  const { id = "" } = useParams();
  const [loading, setLoading] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const fetchGroupTreemap = useCallback(async () => {
    if (!id || !groupData?.id) {
      return;
    }
    try {
      setLoading(true);
      const result = await getGroupTreeMap(id);
      dispatch(setTreeMap(result.data.sort((a, b) => a.groupId - b.groupId)));
    } catch {
      toast.error("Tải dữ liệu thất bại");
    } finally {
      setLoading(false);
    }
  }, [id, dispatch]);
  useEffect(() => {
    fetchGroupTreemap();
    return () => {
      dispatch(setTreeMap([]));
    };
  }, []);
  useEffect(() => {
    if (treeMap) {
      renderTreemap("#treemap", treeMap);
    }
  }, [treeMap]);

  return (
    <div>
      {showCreateGroupChildModal && groupSelected && (
        <CreateChildGroup
          rootId={groupSelected.groupId}
          rootMode={groupData?.mode}
        />
      )}
      {/* {groupData && <GroupHeader role={groupData?.userRole || null} />} */}

      <div className="relative overflow-x-hidden">
        {/* <div onClick={() => setShowSearchBar(true)}>
          <Search />
        </div> */}
        {showSearchBar && <SearchBar onClose={() => setShowSearchBar(false)} />}
        {loading && (
          <TGNLoading
            size="large"
            isFullScreen
          />
        )}
        <div
          id="treemap"
          className="w-full h-[calc(100vh-70px-85px-120px)]"
        ></div>
      </div>
    </div>
  );
};

export default TreeOrg;
