import React, {
  memo,
  useState,
  FC,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from "react";

import {
  ChevronDown,
  ChevronUp,
  CloseFilled,
  Search,
} from "@carbon/icons-react";
import cx from "classnames";
import { select } from "d3-selection";

import { useAppSelector } from "@/redux/store";

import styles from "./SearchBar.module.scss";

type SearchBarProps = {
  onClose: () => void;
};

const SearchBar: FC<SearchBarProps> = ({ onClose }) => {
  const { groupList, zoomBehavior } = useAppSelector((state) => state.group);
  const [keyword, setKeyword] = useState("");
  const [selectingIndex, setSelectingIndex] = useState(-1);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const groupByKeyword = useMemo(() => {
    return groupList.filter((group) =>
      group.groupName.toLowerCase().includes(keyword.toLowerCase().trim())
    );
  }, [groupList, keyword]);
  const inputRef = useRef<HTMLInputElement>(null);
  const selectingIndexRef = useRef(-1);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const handleClickKeyDown = useCallback(() => {
    const index =
      selectingIndexRef.current === groupList.length - 1
        ? 0
        : selectingIndexRef.current + 1;
    selectingIndexRef.current = index;
    setSelectingIndex(index);
  }, [groupList.length]);
  const handleClickKeyUp = useCallback(() => {
    const index =
      selectingIndexRef.current === -1 || selectingIndexRef.current === 0
        ? groupList.length - 1
        : selectingIndexRef.current - 1;
    selectingIndexRef.current = index;
    setSelectingIndex(index);
  }, [groupList.length]);
  const handleChoose = useCallback(() => {
    setSelectedIndex(selectingIndexRef.current);
    const selected = groupList[selectingIndexRef.current].groupId;
    if (!selected) {
      return;
    }
    const svg = select("#treemap");
    const node = select(`#g${selected}`);
    const [x, y] = node
      .attr("transform")
      .slice(10, -1)
      .split(",")
      .map((item) => Number(item.trim()));
    if (zoomBehavior) {
      // eslint-disable-next-line
      zoomBehavior.translateTo(svg as any, (x || 0) + 280, (y || 0) + 100);
    }
  }, [groupList, zoomBehavior]);
  const handleClickItem = (index: number) => {
    setSelectingIndex(index);
    selectingIndexRef.current = index;
    handleChoose();
  };

  useEffect(() => {
    if (!groupByKeyword.length) {
      setSelectingIndex(0);
      return;
    }
    const listener = (e: KeyboardEvent) => {
      switch (e.key) {
        case "ArrowDown": {
          handleClickKeyDown();
          break;
        }
        case "ArrowUp": {
          handleClickKeyUp();
          break;
        }
        case "Enter": {
          handleChoose();
          break;
        }
        default:
          break;
      }
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [
    groupList,
    groupByKeyword.length,
    handleClickKeyDown,
    handleClickKeyUp,
    handleChoose,
  ]);

  return (
    <div className=" absolute w-[280px] h-full bg-white  overflow-y-auto animate-[transform_0.25s] border-r border-solid left-0 top-0">
      <div className="flex align-items-center p-5 border-bottom border-gray-200 ">
        <Search
          color="#98a2b3"
          className="me-2"
        />
        <input
          ref={inputRef}
          type="text"
          className={styles.input}
          placeholder="Tìm kiếm"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <CloseFilled
          className="text-gray-600 cursor-pointer"
          onClick={onClose}
        />
      </div>
      <div className="d-flex justify-content-between align-items-center py-4 px-4">
        <span className="text-gray-600">{groupByKeyword.length} kết quả</span>
        <div className="d-flex align-items-center gap-2">
          <ChevronUp
            className="cursor-pointer"
            size={16}
            onClick={() => {
              handleClickKeyUp();
              handleChoose();
            }}
          />
          <ChevronDown
            className="cursor-pointer"
            size={16}
            onClick={() => {
              handleClickKeyDown();
              handleChoose();
            }}
          />
        </div>
      </div>
      {groupByKeyword.map((group, index) => (
        <div
          key={group.groupId}
          className={cx(
            styles.item,
            (selectingIndex === index || selectedIndex === index) &&
              styles.active
          )}
          onClick={() => handleClickItem(index)}
        >
          {group.groupName}
        </div>
      ))}
    </div>
  );
};

export default memo(SearchBar);
